import { Card, CardContent, Typography } from '@material-ui/core';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import { DialogType } from '_store/application/types';
import { Denm } from '_store/denm/denmsTypes';
import { causesSelector, subCausesSelector } from '_store/denm/selectors';
import { defaultIcon, denmStatuses } from '_store/denm/utils';
import { useSelector } from 'react-redux';
import { Accordion } from '../../../../../components/items/Accordion';
import { CheckboxItem } from '../../../../../components/items/CheckboxItem';
import { DotComponent } from '../../../../../components/scenes/Event/DotComponent';
import { EventStatus } from '../../../../../components/scenes/Event/EventStatus';
import denmIcons from '../../../../icons/denm';
import { iconForCause } from '../../../../icons/utils/denm';
import { toLatLng } from '../../../../routes/MapView/utils';
import CardDetails from './Details';
import { SincroMetaData } from './Sincro';
import styles from './styles';

export interface CardListItemProps {
  denm: Denm;
  asCard: boolean;
  onUpdate?: (idExpanded: string) => void;
  onDelete?: (id: string) => void;
  onDisable?: (id: string) => void;
  onEnable?: (id: string) => void;
  onCheckedChange?: (id: string) => void;
  checked?: boolean;
  showCheckbox?: boolean;
  onClone?: (id: string) => void;
}

const CardListItem: FC<CardListItemProps> = ({
  denm,
  onUpdate,
  onDelete,
  asCard,
  onDisable,
  onEnable,
  onCheckedChange,
  checked,
  showCheckbox = false,
  onClone,
}) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();
  const causes = useSelector(causesSelector);
  const subCauses = useSelector(subCausesSelector);
  const mapRef = useSelector((state: RootState) => state.application.mapRef);

  const onAccordionExpanded = () => {
    const markerPosition = toLatLng(denm?.referencePoint?.coordinates);
    mapRef.flyTo(markerPosition, 15, { animate: true, duration: 1 });

    setTimeout(() => {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DENM_PATH,
          id: denm.id,
        }),
      );
    }, 1000);
  };

  const { isDenmActive, disabled } = denmStatuses(denm);
  const denmCreationDate = new Date(denm.createdAt);

  const subCause = subCauses.find((sc) => sc.id === denm.subCauseId);
  const cause = subCause && causes.find((c) => c.id === subCause.causeId);
  const causeIcon = iconForCause(cause, subCause) ?? defaultIcon;

  const { statusClass, statusText } = EventStatus({
    active: isDenmActive,
    date: denmCreationDate,
    disabled,
  });

  const details = (
    <CardDetails
      denm={denm}
      cause={cause}
      subCause={subCause}
      onUpdate={() => (onUpdate ? onUpdate(denm.id) : null)}
      onDelete={() => (onDelete ? onDelete(denm.id) : null)}
      onClone={() => (onClone ? onClone(denm.id) : null)}
      showButtons={true}
      isActive={isDenmActive}
      onDisable={() => (onDisable ? onDisable(denm.id) : null)}
      onEnable={() => (onEnable ? onEnable(denm.id) : null)}
      isDisabled={disabled}
    />
  );

  const sincroMetaData = <SincroMetaData sincroData={denm.metadata.sincro} />;

  const body = (
    <div className={classes.cardOuterContent}>
      <div className={classes.checkbox}>
        {showCheckbox && (
          <CheckboxItem checked={checked} itemId={denm.id} handleCheckedChange={onCheckedChange} />
        )}
      </div>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>
          {cause?.description} - {subCause?.description}
          <img src={denmIcons[causeIcon]?.png} className={classes.cardHeaderIcon} />
        </Typography>
        <div className={classes.cardHeaderStatus}>
          {DotComponent({
            status: isDenmActive,
            isDisabled: disabled,
          })}
          <Typography variant="caption" className={statusClass}>
            {statusText}
          </Typography>

          <Typography variant="caption" className={classes.cardHeaderDate}>
            {`- ${denmCreationDate.toLocaleString()}`}
          </Typography>
        </div>
        {asCard ? (
          <div style={{ marginTop: '20px' }}>
            <Accordion title={l10n.getString('view-details')} onExpanded={onAccordionExpanded}>
              {details}
            </Accordion>
          </div>
        ) : (
          details
        )}
        {denm.metadata?.sincro && (
          <div style={{ marginTop: '20px' }}>
            <Accordion
              title={l10n.getString('view-details-metadata')}
              onExpanded={onAccordionExpanded}
            >
              {sincroMetaData}
            </Accordion>
          </div>
        )}
      </CardContent>
    </div>
  );

  if (asCard) {
    return <Card className={classes.root}>{body}</Card>;
  }
  return body;
};

export default React.memo(CardListItem);
