import { Button, CardActions, Collapse, Icon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  onExpanded?: () => void;
  className?: string;
  isExpandedByDefault?: boolean;
}

export const Accordion = ({
  title,
  children,
  onExpanded,
  className,
  isExpandedByDefault = false,
}: AccordionProps) => {
  const classes = styles();
  const [expanded, setExpanded] = useState(isExpandedByDefault);

  const handleExpandClick = () => {
    if (!expanded && onExpanded) {
      onExpanded();
    }
    setExpanded(!expanded);
  };
  return (
    <>
      <CardActions
        style={{ padding: 0 }}
        disableSpacing
        onClick={handleExpandClick}
        className={className}
      >
        <Button fullWidth className={classes.viewDetailButton}>
          {title}
          <Icon
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </Icon>
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};
