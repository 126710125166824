import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingsPayload } from '_lib/api';
import { SettingsReducerState } from './settingsTypes';
import { transformObuObjectToArray } from './utils';

const initialState: SettingsReducerState = {
  loading: false,
  distribution: {
    denm: {
      datexii: false,
      emeras: false,
      connex: false,
    },
  },
  storage: {
    cam: {
      obu: [],
      obuFilterEnabled: false,
      simulatorToggle: false,
    },
  },
};

const settingsReducer = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsRequest: (state, _action: PayloadAction<SettingsPayload>) => {
      state.loading = true;
    },
    settingsResponse: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.distribution = action.payload.distribution;
        state.storage = action.payload.storage;
        state.storage.cam.obu =
          state.storage.cam.obu.length === 0
            ? []
            : transformObuObjectToArray(action.payload.storage.cam.obu);
      }
    },
    updateSettings: (state, _action: PayloadAction<SettingsPayload>) => {
      state.loading = true;
    },
    updateSettingsResponse: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.distribution = action.payload.distribution;
        state.storage = action.payload.storage;
        state.storage.cam.obu =
          state.storage.cam.obu.length === 0
            ? []
            : transformObuObjectToArray(action.payload.storage.cam.obu);
      }
    },
    resetForm(_state, _action: PayloadAction<void>) {
      return initialState;
    },
  },
});

export default settingsReducer;
