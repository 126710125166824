import '../../components.scss';

import { useLocalization } from '@fluent/react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { devicesSagas } from '_store/devices/sagas';
import roadsReducer from '_store/roads/roadsReducers';
import React, { FC, ReactElement, useState } from 'react';
import { MapContainer, Polyline } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useSelector } from 'react-redux';

import { useDenmsRequest } from '_store/denm/utils';
import { useIvimsRequest } from '_store/ivim/utils';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { getFromLocalStorage, updateLocalStorage } from '../../../../utils';
import Denms from './Elements/Denms';
import Devices from './Elements/Devices';
import ExtraSelectionElements from './Elements/ExtraSelectionElements';
import Ivims from './Elements/Ivims';
import Roads from './Elements/Roads';
import SelectionElements from './Elements/SelectionElements';
import Vehicles from './Elements/Vehicles';
import RenderPath from './Elements/renderPath';
import MapEvents from './Events/MapEvents';
import useMap from './hooks/useMap';
import styles from './styles';
import { toLatLng } from './utils';

const enum MapType {
  ROADMAP = 'roadmap',
  HYBRID = 'hybrid',
  TERRAIN = 'terrain',
  SATELLITE = 'satellite',
}

const layers = ['event', 'traffic', 'rsu', 'data'];

const MapView: FC = (_props: RouteComponentProps): ReactElement => {
  const classes = styles();
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const mapTypeFromLocalStorage = (getFromLocalStorage('mapType') as MapType) ?? MapType.ROADMAP;

  const [mapType, setMapType] = useState(mapTypeFromLocalStorage);
  const updateMapType = (e) => {
    updateLocalStorage('mapType', e.target.value);
    setMapType(e.target.value);
  };

  const {
    roads,
    devices,
    denms,
    ivim,
    vehicles,
    dialog,
    extra,
    selection,
    activeLayers,
    mapRef,
    loading,
    links,
    loadHandler,
    meterPerPixel,
    centerCoordinates,
    googleMapsApiKey,
    zoom,
  } = useMap();

  const dispatchDenmsRequest = useDenmsRequest({
    currentPage: 1,
  });
  const dispatchIvimsRequest = useIvimsRequest({ currentPage: 1 });
  const selectedRoadSegmentIds = useSelector(selectedRoadSegmentIdsSelector);

  const updateSelectedRoad = (e) => {
    const selectedRoads = e.target.value as string[];
    updateLocalStorage('selectedRoadIds', JSON.stringify(selectedRoads));

    dispatch(roadsReducer.actions.selectRoad(selectedRoads));
    dispatch(devicesSagas.actions.devicesRequest(selectedRoads));

    dispatchDenmsRequest(selectedRoads);
    dispatchIvimsRequest(selectedRoads);
  };

  return (
    <Card className={classes.cardStyle}>
      <CardContent className={classes.cardContent}>
        {loading ? (
          <Typography>{l10n.getString('map-loading')}</Typography>
        ) : (
          <MapContainer
            className={classes.mapContainer}
            zoom={zoom}
            center={centerCoordinates}
            scrollWheelZoom={true}
            doubleClickZoom={false}
            whenCreated={loadHandler}
            // onClick={pointClicked}
          >
            <ReactLeafletGoogleLayer
              key={mapType}
              type={mapType}
              continuousWorld={true}
              apiKey={googleMapsApiKey}
            />
            <div className={classes.mapButtons}>
              <ButtonGroup size="large" disableElevation variant="contained">
                {layers.map((layer) => (
                  <Button
                    key={layer}
                    className={activeLayers[layer] ? 'Map-buttons-sel' : 'Map-buttons'}
                    onClick={() => {
                      updateLocalStorage(layer, !activeLayers[layer]);
                      dispatch(applicationReducer.actions.toggleActiveLayer(layer));
                    }}
                  >
                    {l10n.getString(`map-filter-${layer}`)}
                  </Button>
                ))}
              </ButtonGroup>
              <div>
                <Select
                  className="Map-Road-select"
                  onChange={updateSelectedRoad}
                  value={selectedRoadSegmentIds ?? []}
                  multiple
                >
                  {roads.map((road, key) => (
                    <MenuItem key={key} value={road.id}>
                      {road.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select className="Map-Type-select" onChange={updateMapType} value={mapType}>
                  {[MapType.ROADMAP, MapType.HYBRID, MapType.TERRAIN, MapType.SATELLITE].map(
                    (type, key) => (
                      <MenuItem key={key} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)} map
                      </MenuItem>
                    ),
                  )}
                </Select>
              </div>
            </div>
            {mapRef && (
              <>
                {roads && <Roads roads={roads} />}
                {devices && activeLayers.rsu && <Devices devices={devices} mapRef={mapRef} />}
                {denms && activeLayers.event && <Denms denms={denms} mapRef={mapRef} />}
                {ivim && activeLayers.traffic && <Ivims ivims={ivim} mapRef={mapRef} />}
                {vehicles && activeLayers.data && <Vehicles vehicles={vehicles} mapRef={mapRef} />}
              </>
            )}

            {selection.style && <SelectionElements selection={selection} />}

            <ExtraSelectionElements extra={extra} />

            <MapEvents />

            {/* TODO: reimplement this stuff */}
            {Object.entries(links).map(([id, l]) => (
              <Polyline
                key={`link-${id}`}
                positions={l
                  .filter((n) => n.loc && n.loc.coordinates)
                  .map((n) => toLatLng(n.loc.coordinates))}
                weight={1}
                color="#0ff"
                // geodesic={true}
              />
            ))}

            {dialog && (
              <RenderPath dialog={dialog} denms={denms} ivim={ivim} meterPerPixel={meterPerPixel} />
            )}

            {/* {trafficData && activeLayers['traffic']
            ? trafficData.map((link, i) => (
                <Polyline
                  key={`trafficlink-${link.link}-${i}`}
                  path={link.polyline}
                  options={{
                    strokeWeight: 5,
                    zIndex: 10,
                    strokeColor: colorByFlowCode(link.flowCode),
                    geodesic: true,
                  }}
                />
              ))
            : null} */}
          </MapContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default React.memo(MapView);
