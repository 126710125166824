import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';
import { devicesAdapter } from './devicesReducers';

const devicesStateDomain = (state: RootState) => state.devices;

export const loadingSelector = createSelector(devicesStateDomain, (device) => device.loading);

export const devicesSelector = createSelector(devicesStateDomain, (device) =>
  devicesAdapter.getSelectors().selectAll(device),
);
