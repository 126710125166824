import { Dialog, DialogType } from '_store/application/types';
import React, { Fragment } from 'react';
import { Circle, Polyline } from 'react-leaflet';

import { DenmColors, toLatLng } from '../utils';
import { LatLngExpression } from 'leaflet';
import { Denm } from '_store/denm/denmsTypes';
import { Ivim } from '_store/ivim/ivimsTypes';
import { Coordinate } from '_lib/api';

const getPositions = (index: number, coordinates: number[][]): LatLngExpression[] => {
  const toLatLng = (coord: number[]): LatLngExpression => [coord[1], coord[0]];
  const nextIndex = index + 1;
  const positions = [
    toLatLng(coordinates[index]),
    ...(nextIndex < coordinates.length ? [toLatLng(coordinates[nextIndex])] : []),
  ];
  return positions;
};

interface renderPathProps {
  dialog: Dialog;
  denms: Denm[];
  ivim: Ivim[];
  meterPerPixel: () => number;
}

const renderPath = ({ dialog, denms, ivim, meterPerPixel }: renderPathProps) => {
  const radiusStandard = 6;
  const myRadiusDinamic = Math.round(radiusStandard * meterPerPixel());
  switch (dialog.type) {
    case DialogType.DENM:
    case DialogType.DENM_PATH: {
      const event = denms.find((d) => d.id === dialog.id);

      if (!event) {
        return null;
      }

      const isMultiLineString = event.traceZoneCoordinates.type === 'MultiLineString';
      const mainTraceCoordinates = event.traceZoneCoordinates.coordinates[0];
      const traceZoneCoordinates = event.traceZoneCoordinates.coordinates.slice(1);

      return (
        <>
          <Circle
            center={toLatLng(event.referencePoint.coordinates)}
            // radius={6}
            radius={myRadiusDinamic}
            color={DenmColors.REFERENCE_POINT}
            weight={3}
          />
          {isMultiLineString &&
            mainTraceCoordinates?.map((pointCoordinates, index) => {
              const positions = getPositions(index, mainTraceCoordinates);

              return (
                <Fragment key={`${index}`}>
                  <Circle
                    center={toLatLng(pointCoordinates)}
                    // radius={6}
                    radius={myRadiusDinamic}
                    weight={3}
                    color={DenmColors.MAIN_TRACE}
                  />
                  <Polyline weight={3} color={DenmColors.MAIN_TRACE} positions={positions} />
                </Fragment>
              );
            })}
          {!isMultiLineString && (
            <>
              {event.traceZoneCoordinates.coordinates.map((pointCoordinates, key) => (
                <Circle
                  key={key}
                  center={toLatLng(pointCoordinates as unknown as Coordinate)}
                  // radius={6}
                  radius={myRadiusDinamic}
                  weight={3}
                  color={DenmColors.MAIN_TRACE}
                />
              ))}
              <Polyline
                weight={3}
                color={DenmColors.MAIN_TRACE}
                positions={event.traceZoneCoordinates.coordinates.map((pointCoordinates) =>
                  toLatLng(pointCoordinates as unknown as Coordinate),
                )}
              />
            </>
          )}
          {isMultiLineString &&
            traceZoneCoordinates?.map((coordinates, traceIndex) => {
              return coordinates.map((pointCoordinates, index) => {
                const positions = getPositions(index, coordinates);

                return (
                  <Fragment key={`${traceIndex}-${index}`}>
                    <Circle
                      center={toLatLng(pointCoordinates)}
                      // radius={6}
                      radius={myRadiusDinamic}
                      weight={3}
                      color={DenmColors.TRACE}
                    />
                    <Polyline weight={3} color={DenmColors.TRACE} positions={positions} />
                  </Fragment>
                );
              });
            })}

          {event.historyZoneCoordinates && (
            <>
              {event.historyZoneCoordinates.coordinates.map((pointCoordinates, key) => (
                <Circle
                  key={key}
                  center={toLatLng(pointCoordinates)}
                  // radius={6}
                  radius={myRadiusDinamic}
                  weight={3}
                  color="#fc0"
                />
              ))}
              <Polyline
                weight={3}
                color="#fc0"
                positions={event.historyZoneCoordinates.coordinates.map((pointCoordinates) =>
                  toLatLng(pointCoordinates),
                )}
              />
            </>
          )}
        </>
      );
    }
    case DialogType.IVIM:
    case DialogType.IVIM_PATH: {
      const event = ivim.find((i) => i.id === dialog.id);
      if (!event) {
        return null;
      }
      return (
        <>
          <Circle
            center={toLatLng(event.referencePoint.coordinates)}
            radius={myRadiusDinamic}
            color={DenmColors.REFERENCE_POINT}
            weight={3}
          />
          <Circle
            center={toLatLng(event.detZoneCoordinates.coordinates[0])}
            // radius={6}
            radius={myRadiusDinamic}
            weight={3}
            color="#f0f"
          />
          {event.detZoneCoordinates.coordinates.map((coordinates, key) => (
            <Circle
              key={key}
              center={toLatLng(coordinates)}
              radius={myRadiusDinamic}
              weight={3}
              color="#0fc"
            />
          ))}
          {event.relZoneCoordinates.coordinates.map((coordinates, key) => (
            <Circle
              key={key}
              center={toLatLng(coordinates)}
              radius={myRadiusDinamic}
              weight={3}
              color="#00f"
            />
          ))}
          <Polyline
            weight={3}
            color="#0fc"
            positions={event.detZoneCoordinates.coordinates.map(toLatLng)}
          />
          <Polyline
            weight={3}
            color="#00f"
            positions={event.relZoneCoordinates.coordinates.map(toLatLng)}
          />
        </>
      );
    }
    default:
      return null;
  }
};

export default renderPath;
